import { CustomText, SimpleCheckBox } from "@temp/components";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./style.scss";
import { PrimaryButton, OutLineButton } from "@components";
import { useDispatch, useSelector } from "react-redux";
import { DrawerAction, ProfileAction, LoadAction } from "@temp/store/actions";
import { getDate, getTime, phoneNumberFormater, triggerMixpanelEvent } from "@temp/utils/helper";
import TitleLayout from "../../../components/TitleLayout";
var numeral = require("numeral");
import { RootState } from "@temp/components/Loader";
import {
  INQUIRY_TYPE,
  LOAD_TYPE,
  TRANSACTION_TYPE,
  QUOTE_BY,
} from "@temp/constants";
import moment from "moment";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import FadeInComponent from "@temp/components/FadeInComponent/FadeInComponent";
import dayjs from "dayjs";

export const SelectRecipient: React.FC = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const postLoadData = useSelector((state: RootState) => state.Profile.postLoad);
  const PostLoad = useSelector((state: RootState) => state?.Profile?.postLoad);
  const LoadDetailData = useSelector((state: RootState) => state?.Load?.loadDetail);
  const metaData = useSelector((state: RootState) => state?.Auth?.metaData);
  const mg_admin = metaData?.mg_recipient?.PHONE_NO;
  const postLoadMeta = useSelector((state: RootState) => state?.Profile?.postLoadMeta);
  const contacts = useSelector((state: RootState) => state?.Drawer?.networkData || []);
  const load_type = postLoadMeta.load_types?.find((val: any) => val?.id === PostLoad?.load_type);
  const inquiry_type = postLoadMeta.inquiry_types?.find((val: any) => val?.id === PostLoad?.inquiry_type);
  const transaction_type = postLoadMeta.transaction_types?.find((val: any) => val?.title === TRANSACTION_TYPE.bid);
  const [recipients, setRecipients] = useState([]);
  const [selectAllRecipients, setSelectAllRecipients] = useState(false);
  const [search, setSearch] = useState("");
  // console.log(postLoadData,"postLoadData")

  

  useEffect(() => {
    // if (contacts?.length) {
    //   if (contacts?.length == recipients?.length) {
    //     
    //     setSelectAllRecipients(true)
    //   } else {
    //     setSelectAllRecipients(false)
    //   }
    // }
    const allNetworks = contacts.filter(each=> each?.networkUser?.name !==  "Unregistered User")
    setSelectAllRecipients(allNetworks?.length === recipients?.length ? true : false)
   
  }, [recipients])

  const handleCheckBox = (e: any, value: object) => {
    let temp = recipients;
    if (e.target.checked) {
      temp.push({
        phone_code: value?.networkUser?.phone_code,
        phone_no: value?.networkUser?.phone_no,
      });
    } else {

      temp = recipients?.filter(
        (val: any) => val.phone_no !== value?.networkUser?.phone_no
      );
    }
    // dispatch(ProfileAction.postLoadData({ recipients: temp }));
    setRecipients([...temp]);
    const regUsers = contacts?.filter(each=> each?.networkUser?.name !== "Unregistered User")
    
    if (regUsers?.length == temp?.length) {
      
      setSelectAllRecipients(true)
    } else {
      setSelectAllRecipients(false)
    }
  };

  const handleCheckBoxForAllRecipients = () => {
    let forAllRecipients = [];
    contacts.forEach((item, index) => {
      
      if (item?.networkUser?.name !== "Unregistered User") {
        forAllRecipients.push({
          phone_code: item?.networkUser?.phone_code,
          phone_no: item?.networkUser?.phone_no,
        })
      }
    })
    setRecipients(forAllRecipients);
  }

  const Card = ({ val, size = 6, index }) => {
    
    let isChecked = recipients.find((values) => values.phone_no === val?.networkUser?.phone_no);
    return (
      <div key={index} data-size={size == 6 ? "50" : "100"} className={`recipentGridBox ${isChecked ? 'checked' : ''}`}>
        {val?.networkUser?.name !== "Unregistered User"
          ?
          <div className="selectRecipentCheckBox">
            <SimpleCheckBox
              handleChange={(e) => {
                handleCheckBox(e, val);

              }}
              checked={isChecked ? true : false}
            />
          </div>
          : null
        }
        <div className="insideRecipentContentBoxWrap">
          <img
            src={
              val?.networkUser?.picture
                ? val?.networkUser?.picture
                : metaData?.default_avatar
            }
            className="userImg"
          />
          <div className="w-100">
            {val?.networkUser?.company?.length ?
              <>
                {val?.networkUser?.company && val?.networkUser?.company.length ? (
                  <p className="companyTitle">
                    {val?.networkUser?.company && val?.networkUser?.company.length
                      ? val?.networkUser?.company[0]?.name || ""
                        ? val?.networkUser?.company[0]?.name || ""
                        : ""
                      : ""}
                  </p>
                ) : ""}
                {val?.networkUser?.name ? <p className="name">{val?.networkUser?.name || ""} </p> : ""}
                {val?.networkUser?.phone_no ? <p className="phone">{`${val?.networkUser?.phone_no !== mg_admin ? `${phoneNumberFormater(val?.networkUser?.phone_code, val?.networkUser?.phone_no)}` : " "} `}</p> : ""}
                {val?.networkUser?.name == "Unregistered User" ? <p className="name">Invite Sent</p> : ""}
              </> :
              <>
                {val?.networkUser?.name ? <p className="companyTitle">{val?.networkUser?.name || ""}</p> : ""}
                {val?.networkUser?.company_name ? <p className="name">{val?.networkUser?.company_name}</p> : ""}
                {val?.networkUser?.phone_no !== mg_admin ? <p className="phone"> {`${val?.networkUser?.phone_no !== mg_admin ? `${phoneNumberFormater(val?.networkUser?.phone_code, val?.networkUser?.phone_no)}` : " "} `}</p> : ""}
                {val?.networkUser?.name == "Unregistered User" ? <p className="name">Invite Sent</p> : ""}
              </>
            }
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(DrawerAction.GetNetwork({ only_company_users: "" }));
    }, 100);
  }, []);

  if (contacts.length) {
    let find_index_mg_approved = contacts.findIndex(item => item?.networkUser?.phone_no == mg_admin)
    let find_mg_approved = contacts.find(item => item?.networkUser?.phone_no == mg_admin)
    if (find_mg_approved) {
      contacts.splice(find_index_mg_approved, 1)
      contacts.unshift(find_mg_approved)
    }
  }


  useEffect(() => {
    if (contacts.length) {
      let new_recipt = []
      contacts.forEach((item) => {
        if (postLoadData?.recipients.find(recpt => recpt.phone_no == item?.networkUser.phone_no)) {
          new_recipt.push({ phone_no: item?.networkUser.phone_no, phone_code: item?.networkUser.phone_code })
        }
      })
      if (postLoadData?.from !== 'forward') {
        setRecipients(new_recipt?.length ? new_recipt :
          [
            {
              phone_no: mg_admin,
              phone_code: "92",
            },
          ]
        )
      } else {
        setRecipients([])
      }
    }
  }, [contacts])

  const getDifference = (array1, array2) => {
    return array1.filter((object1) => {
      return !array2.some((object2) => {
        return object1.networkUser?.phone_no === object2.phone_no;
      });
    });
  }


  let cb = ({ status }) => {
    
    if (PostLoad?.isDraft) {
      triggerMixpanelEvent("Post a Load from Draft complete", "Click on Post a Load button to make a new load")
    } else if (PostLoad?.from == "clone") {

    } else {

    }
    triggerMixpanelEvent("Post a Load complete", "Post a Load process is completed")
    history.push(`postSuccessfully?status=${status}`);
  };

  const forwardCallback = () => {
    history.push('/home')
  }

  const onSubmit = (status) => {
    let payload = { status };
    let new_recipients = recipients.filter((item) => item?.phone_no);
    if (inquiry_type?.title === INQUIRY_TYPE?.spotRate) {
      if (load_type.title === LOAD_TYPE.fcl && new_recipients.length) {
        payload = getFclData(payload);
      } else if (
        (load_type?.title === LOAD_TYPE?.bulk) &&
        new_recipients?.length
      ) {
        payload = getLclData(payload);
      }
    } else {
      if (load_type?.title === LOAD_TYPE?.fcl) {
        payload = getFclDataForContract(payload);
      } else if (
        load_type?.title === LOAD_TYPE?.bulk
      ) {
        payload = getLclDataForContract(payload);
      }
    }
    // console.log(payload,"final payload")
    // return
    if (PostLoad?.isDraft) {
      dispatch(
        LoadAction.actionCreateFromDraft({
          id: LoadDetailData?.id,
          data: payload,
          cb,
        })
      );
    } else {
      if (PostLoad?.from == 'forward') {
        dispatch(
          LoadAction.forwardLoad({
            forwardData: { ...payload, cb: forwardCallback },
            load_id: PostLoad?.load_id,
          })
        );
      } else {
        dispatch(ProfileAction.PostLoad({ data: payload, cb }));
      }
    }
  };

  const removeDuplicate = (arr) => {
    let result = arr.reduce((unique, o) => {
      if (!unique.some(obj => obj.phone_no === o.phone_no)) {
        unique.push(o);
      }
      return unique;
    }, []);
    return result
  }

  const getFclData = (postData: any) => {
    // return
    postData = {
      ...postData,
      ...getCommonData(postData),

      is_container_deposit_by_transporter:
        postLoadData?.additionalScreen?.is_container_deposit_by_transporter ||
        false,
      shipping_line_name:
        postLoadData?.additionalScreen?.shipping_line_name || null,
      container_deposit_amount:
        postLoadData?.additionalScreen?.container_deposit_amount || null,
      is_quote_price_deposit_fee_inclusive:
        postLoadData?.additionalScreen?.is_quote_price_deposit_fee_inclusive,
      quote_by: QUOTE_BY.vehicle,
    };

    postData["categories"]["items"] = getVehiclesList(
      postLoadData?.vehicleScreen?.addedVehicles || []
    );
    return postData;
  };

  const getFormattedLocation = (location: any) => {
    const data = location?.map((loc: any) => {
      const pickup = {
        lat: loc?.pickup?.lat,
        description: loc?.pickup?.description,
        raw_address: loc?.pickup?.address || loc?.pickup?.raw_address,
        long: loc?.pickup?.lng,
        title: loc?.pickup?.title || loc?.pickup?.title,
        contact_name: loc?.pickup?.contact_name,
        contact_phone_no: loc?.pickup?.contact_phone_no,
        contact_phone_code: loc?.pickup?.contact_phone_code,
        city: loc?.pickup?.city,
        pickup_accordion: loc?.pickup?.pickup_accordion,

      };
      if (
        load_type?.title === LOAD_TYPE.bulk ||
        load_type?.title === LOAD_TYPE.bulk
      ) {
        pickup.attributes = loc?.pickup?.attribute;
        if (postLoadData?.contractDetails?.quote_by === QUOTE_BY.weightVolume) {

          pickup.attributes.preferred_vehicle_id =
            pickup?.attributes?.PreferredVehicleType?.id;

          pickup.attributes.package_type_id =
            pickup?.attributes?.packageType?.id;

          pickup.attributes.package_type_other =
            pickup?.attributes?.package_type_other || "";
        }
      }
      const dropoff = {
        lat: loc?.dropoff?.lat,
        description: loc?.dropoff?.description,
        raw_address: loc?.dropoff?.address || loc?.dropoff?.raw_address,
        long: loc?.dropoff?.lng ,
        title: loc?.dropoff?.title || loc?.dropoff?.title,
        contact_name: loc?.dropoff?.contact_name,
        contact_phone_no: loc?.dropoff?.contact_phone_no,
        contact_phone_code: loc?.dropoff?.contact_phone_code,
        city: loc?.dropoff?.city,
        dropoff_accordion: loc?.dropoff?.dropoff_accordion,
        

      };
      if (
        load_type?.title === LOAD_TYPE.lcl ||
        load_type?.title === LOAD_TYPE.bulk
      ) {
        dropoff.attributes = loc?.pickup?.attribute;
        if (postLoadData?.contractDetails?.quote_by === QUOTE_BY.weightVolume) {
          dropoff.attributes.preferred_vehicle_id =
            dropoff?.attributes?.PreferredVehicleType?.id;

          dropoff.attributes.package_type_id =
            dropoff?.attributes?.packageType?.id;
        }
      }
      const items = getVehiclesList(loc?.items);
      return { pickup: pickup, dropoff: dropoff, items: items?.length ? items : loc?.added_units || [], added_units: loc?.added_units };
    });
    return data;
  };

  const getFclDataForContract = (postData: any) => {
    postData = { ...postData, ...getCommonDataForContract(postData) };
    postData["container_deposit_amount"] =
      postLoadData?.additionalScreen?.container_deposit_amount;
    postData["is_container_deposit_by_transporter"] =
      postLoadData?.additionalScreen?.is_container_deposit_by_transporter;
    postData["is_shipper_owned_container"] =
      postLoadData?.additionalScreen?.is_shipper_owned_container;
    postData["shipping_line_name"] =
      postLoadData?.additionalScreen?.shipping_line_name;
    postData["is_quote_price_deposit_fee_inclusive"] =
      postLoadData?.additionalScreen?.is_quote_price_deposit_fee_inclusive;
    postData["categories"] = getFormattedLocation(PostLoad?.location);

    postData["is_cross_stuffing"] =
      postLoadData?.additionalScreen?.is_cross_stuffing;
    postData["is_box_vehicle_required"] =
      postLoadData?.additionalScreen?.is_box_vehicle_required;
    postData["package_no"] = postLoadData?.additionalScreen?.package_no;

    return postData;

  };

  const getLclDataForContract = (postData: any) => {
    postData = { ...postData, ...getCommonDataForContract(postData) };
    postData["container_deposit_amount"] =
      postLoadData?.additionalScreen?.container_deposit_amount;
    postData["is_container_deposit_by_transporter"] =
      postLoadData?.additionalScreen?.is_container_deposit_by_transporter;
    postData["shipping_line_name"] =
      postLoadData?.additionalScreen?.shipping_line_name;
    postData["is_quote_price_deposit_fee_inclusive"] =
      postLoadData?.additionalScreen?.is_quote_price_deposit_fee_inclusive;
    postData["quote_by"] = postLoadData?.contractDetails?.quote_by;
    postData["categories"] = getFormattedLocation(PostLoad?.location);
    if (postLoadData?.contractDetails?.is_unit_basis) {
      postData["is_unit_basis"] = true
    }
    return postData;
  };

  const getCommonDataForContract = (postData: any) => {
    postData["contract_name"] = postLoadData?.contractDetails?.contract_name;
    postData["instructions"] = postLoadData?.instructions || null;
    postData["additional_requirements"] =
      postLoadData?.additional_requirements || null;

    postData["instructions_voice_note"] =
      postLoadData?.instructions_voice_note?.file_url || null;

    postData["contract_start_date"] =
      String(getDate(postLoadData?.contractDetails?.contract_start_date)) +
      " " +
      String(getTime(postLoadData?.contractDetails?.contract_start_date));
    postData["contract_end_date"] =
      String(getDate(postLoadData?.contractDetails?.contract_end_date)) +
      " " +
      String(getTime(postLoadData?.contractDetails?.contract_end_date));
    postData["deadline_at"] = PostLoad?.isDraft ?
      String(getDate(postLoadData?.contractDetails?.deadline_date)) +
      " " +
      String(postLoadData?.contractDetails?.deadline_time)
      // String(postLoadData?.contractDetails?.deadline_time)
      :
      String(getDate(postLoadData?.contractDetails?.deadline_date)) +
      " " +
      // String(getTime(postLoadData?.contractDetails?.deadline_time));
      String(postLoadData?.contractDetails?.deadline_time)
    postData["remarks"] = postLoadData?.contractDetails?.contract_remarks;

    postData["is_covid_certf_req_driver"] =
      postLoadData?.additionalScreen?.is_covid_certf_req_driver;
    postData["is_hse_compliant_vehicle"] =
      postLoadData?.additionalScreen?.is_hse_compliant_vehicle;

    postData["is_weightment_received_required"] =
      postLoadData?.additionalScreen?.is_weightment_received_required;

    postData["weightment_received_name"] =
      postLoadData?.additionalScreen?.weightment_received_name;

    postData["is_bonded_vehicle_required"] =
      postLoadData?.additionalScreen?.is_bonded_vehicle_required;

    postData["is_tarpaulin_plastic_cover"] =
      postLoadData?.additionalScreen?.is_tarpaulin_plastic_cover;

    postData["is_under_hook_delivery_required"] =
      postLoadData?.additionalScreen?.is_under_hook_delivery_required;

    postData["under_hook_delivery"] =
      postLoadData?.additionalScreen?.under_hook_delivery;

    postData["driver_requried_document_details"] =
      postLoadData?.additionalScreen?.driver_requried_document_details;

    // postData["rate_validity_date"] = moment(postLoadData?.additionalScreen?.rate_validity_date).format("YYYY-MM-DD");



    postData["is_vehicle_tracking"] =
      postLoadData?.additionalScreen?.is_vehicle_tracking;
    postData["is_advance_vehicle_driver_docs"] =
      postLoadData?.additionalScreen?.is_advance_vehicle_driver_docs;
    postData["is_driver_negative_pcr_required"] =
      postLoadData?.additionalScreen?.is_driver_negative_pcr_required;
    postData["detention_included_transport"] =
      postLoadData?.additionalScreen?.detention_included_transport;


    postData["is_cross_stuffing_required"] =
      postLoadData?.additionalScreen?.is_cross_stuffing_required;
    postData["is_cross_stuffing_box_vehicle_required"] =
      postLoadData?.additionalScreen?.is_cross_stuffing_box_vehicle_required;
    postData["cross_stuffing_no_of_packages"] = postLoadData?.additionalScreen?.cross_stuffing_no_of_packages;

    postData["cargo_description"] =
      postLoadData?.vehicleScreen?.values?.cargo_description ?
        postLoadData?.vehicleScreen?.values?.cargo_description :
        postLoadData?.cargo_description;

    postData["inquiry_type_id"] = postLoadData?.inquiry_type;
    postData["load_type_id"] = postLoadData?.load_type;
    postData["transaction_type_id"] = transaction_type?.id || null;
    postData["pt_is_advance"] = postLoadData?.PaymentScreen?.pt_is_advance;


    postData["pt_is_ondelivery"] =
      postLoadData?.PaymentScreen?.pt_is_ondelivery;
    postData["pt_is_credit"] = postLoadData?.PaymentScreen?.pt_is_credit;
    postData["is_pt_fuel_card"] = postLoadData?.PaymentScreen?.is_pt_fuel_card;
    postData["is_gst_inclusive"] =
      postLoadData?.PaymentScreen?.is_gst_inclusive;
    postData["is_withholding_tax_inclusive"] =
      postLoadData?.PaymentScreen?.is_withholding_tax_inclusive;
    postData["pt_advance_value"] =
      postLoadData?.PaymentScreen?.pt_advance_value || 0;
    postData["pt_ondelivery_value"] =
      postLoadData?.PaymentScreen?.pt_ondelivery_value || 0;
    postData["pt_credit_days"] =
      postLoadData?.PaymentScreen?.pt_credit_days || 0;
    postData["pt_credit_percentage"] =
      postLoadData?.PaymentScreen?.pt_credit_percentage || 0;
    postData["pt_fuel_card_value"] =
      postLoadData?.PaymentScreen?.pt_fuel_card_value || 0;
    postData["sales_tax_province"] =
      postLoadData?.PaymentScreen?.sales_tax_province || "";

    postData["documents"] = postLoadData?.documents || null;
    postData["recipients"] = removeDuplicate(recipients) || null;
    postData["ref_no"] = postLoadData?.ref_no || null;
    postData["cb"] = success;
    return postData;
  };

  const getCommonData = (postData: any) => {
    postData["title"] = postLoadData?.vehicleScreen?.values?.load_name;
    postData["cargo_description"] =
      postLoadData?.vehicleScreen?.values?.cargo_description;
    postData["inquiry_type_id"] = postLoadData?.inquiry_type;
    postData["load_type_id"] = postLoadData?.load_type;
    postData["transaction_type_id"] = postLoadData?.transaction_type_id;
    postData["instructions"] = postLoadData?.instructions || null;
    postData["additional_requirements"] =
      postLoadData?.additional_requirements || null;
    postData["instructions_voice_note"] =
      postLoadData?.instructions_voice_note?.file_url || null;
    postData["pt_is_advance"] = postLoadData?.PaymentScreen?.pt_is_advance;
    postData["pt_is_ondelivery"] =
      postLoadData?.PaymentScreen?.pt_is_ondelivery;
    postData["pt_is_credit"] = postLoadData?.PaymentScreen?.pt_is_credit;
    postData["is_pt_fuel_card"] = postLoadData?.PaymentScreen?.is_pt_fuel_card;
    postData["is_gst_inclusive"] =
      postLoadData?.PaymentScreen?.is_gst_inclusive;
    postData["is_withholding_tax_inclusive"] =
      postLoadData?.PaymentScreen?.is_withholding_tax_inclusive;
    postData["pt_advance_value"] =
      postLoadData?.PaymentScreen?.pt_advance_value || 0;
    postData["pt_ondelivery_value"] =
      postLoadData?.PaymentScreen?.pt_ondelivery_value || 0;
    postData["pt_credit_days"] =
      postLoadData?.PaymentScreen?.pt_credit_days || 0;
    postData["pt_credit_percentage"] =
      postLoadData?.PaymentScreen?.pt_credit_percentage || 0;
    postData["pt_fuel_card_value"] =
      postLoadData?.PaymentScreen?.pt_fuel_card_value || 0;
    postData["sales_tax_province"] =
      postLoadData?.PaymentScreen?.sales_tax_province || "";
    postData["categories"] = {};
    postData["categories"]["pickup"] = {};
    postData["categories"]["pickup"]["lat"] = postLoadData?.location[0].lat;
    postData["categories"]["pickup"]["description"] = postLoadData?.location[0]?.description;
    postData["categories"]["pickup"]["raw_address"] = postLoadData?.location[0]?.address;
    postData["categories"]["pickup"]["long"] = postLoadData?.location[0]?.lng ;
    postData["categories"]["pickup"]["title"] = postLoadData?.location[0]?.title;
    postData["categories"]["pickup"]["contact_name"] = postLoadData?.location[0]?.contact_name;
    postData["categories"]["pickup"]["contact_phone_no"] = postLoadData?.location[0]?.contact_phone_no;
    postData["categories"]["pickup"]["contact_phone_code"] = postLoadData?.location[0]?.contact_phone_code;
    postData["categories"]["pickup"]["city"] = postLoadData?.location[0]?.city;
    postData["categories"]["pickup"]["pickup_accordion"] = postLoadData?.location[0]?.pickup_accordion;




    postData["pickup_date"] = getDate(postLoadData?.responseDeadline?.pickupDate);
    if (postLoadData?.isDraft) {
      postData["pickup_time"] = postLoadData?.responseDeadline?.pickupTime ? dayjs(`${postLoadData?.responseDeadline?.pickupDate} ${postLoadData?.responseDeadline?.pickupTime}`).format("HH:mm:ss") : null

    } else {
      postData["pickup_time"] = postLoadData?.responseDeadline?.pickupTime ? postLoadData?.responseDeadline?.pickupTime : null;
    }

    postData["additional_pickups"] = postLoadData?.additional_pickups?.map((item) => { return { ...item, raw_address: item?.address, long: item?.lng } }),
      postData["additional_dropoffs"] = postLoadData?.additional_dropoffs?.map((item) => { return { ...item, raw_address: item?.address, long: item?.lng } })
    // delivery

    if (!postLoadData?.isDraft) {
      postData["delivery_at"] =
        postLoadData?.responseDeadline?.deliveryDate &&
          postLoadData?.responseDeadline?.deliveryTime
          ? String(getDate(postLoadData?.responseDeadline?.deliveryDate)) +
          " " +
          String(postLoadData?.responseDeadline?.deliveryTime) :
          postLoadData?.responseDeadline?.deliveryDate
            ? moment(postLoadData?.responseDeadline?.deliveryDate).format('YYYY-MM-DD HH:mm:ss') : null
    } else {
      // postData["delivery_at"] = postLoadData?.responseDeadline?.deliveryDate ? moment(postLoadData?.responseDeadline?.deliveryDate).format('YYYY-MM-DD HH:mm:ss') : null
      postData["delivery_at"] =
      postLoadData?.responseDeadline?.deliveryDate &&
        postLoadData?.responseDeadline?.deliveryTime
        ? String(getDate(postLoadData?.responseDeadline?.deliveryDate)) +
        " " +
        String(postLoadData?.responseDeadline?.deliveryTime) :
        postLoadData?.responseDeadline?.deliveryDate
          ? moment(postLoadData?.responseDeadline?.deliveryDate).format('YYYY-MM-DD HH:mm:ss') : null
    }

    if (!postLoadData?.isDraft) {
      postData["empty_return_deadline_at"] =
        postLoadData?.responseDeadline?.emptyReturnDeadlineAtDate &&
          postLoadData?.responseDeadline?.emptyReturnDeadlineAtTime
          ? String(getDate(postLoadData?.responseDeadline?.emptyReturnDeadlineAtDate)) +
          " " +
          String(postLoadData?.responseDeadline?.emptyReturnDeadlineAtTime)
          : postLoadData?.responseDeadline?.emptyReturnDeadlineAtDate ? moment(postLoadData?.responseDeadline?.emptyReturnDeadlineAtDate).format('YYYY-MM-DD HH:mm:ss') : null;
    } else {
      // postData["empty_return_deadline_at"] = postLoadData?.responseDeadline?.emptyReturnDeadlineAtDate ? moment(postLoadData?.responseDeadline?.emptyReturnDeadlineAtDate).format('YYYY-MM-DD HH:mm:ss') : null
      postData["empty_return_deadline_at"] =
      postLoadData?.responseDeadline?.emptyReturnDeadlineAtDate &&
        postLoadData?.responseDeadline?.emptyReturnDeadlineAtTime
        ? String(getDate(postLoadData?.responseDeadline?.emptyReturnDeadlineAtDate)) +
        " " +
        String(postLoadData?.responseDeadline?.emptyReturnDeadlineAtTime)
        : postLoadData?.responseDeadline?.emptyReturnDeadlineAtDate ? moment(postLoadData?.responseDeadline?.emptyReturnDeadlineAtDate).format('YYYY-MM-DD HH:mm:ss') : null;
    }

    // rate validity
    if (!postLoadData?.isDraft) {
      postData["rate_validity_date"] =
        postLoadData?.responseDeadline?.rateValidityDate
          ? String(getDate(postLoadData?.responseDeadline?.rateValidityDate))
          : null;
    } else {
      postData["rate_validity_date"] = postLoadData?.responseDeadline?.rateValidityDate ? moment(postLoadData?.responseDeadline?.rateValidityDate).format('YYYY-MM-DD') : null
    }


    postData["empty_return_yard_name"] =
      // postLoadData?.responseDeadline?.empty_return_yard_name || null;
      postLoadData?.empty_return_yard_name || null;


    postData["is_empty_return"] =
      // postLoadData?.responseDeadline?.is_empty_return || false;
      postLoadData?.is_empty_return || false;

    postData["categories"]["dropoff"] = {};
    postData["categories"]["dropoff"]["lat"] = postLoadData?.location[1].lat;
    postData["categories"]["dropoff"]["description"] = postLoadData?.location[1]?.description;
    postData["categories"]["dropoff"]["raw_address"] = postLoadData?.location[1]?.address;
    postData["categories"]["dropoff"]["long"] = postLoadData?.location[1]?.lng ;
    postData["categories"]["dropoff"]["title"] = postLoadData?.location[1]?.title;
    postData["categories"]["dropoff"]["contact_name"] = postLoadData?.location[1]?.contact_name;
    postData["categories"]["dropoff"]["contact_phone_no"] = postLoadData?.location[1]?.contact_phone_no;
    postData["categories"]["dropoff"]["contact_phone_code"] = postLoadData?.location[1]?.contact_phone_code;
    postData["categories"]["dropoff"]["city"] = postLoadData?.location[1]?.city;
    postData["categories"]["dropoff"]["dropoff_accordion"] = postLoadData?.location[1]?.dropoff_accordion;



    postData["deadline_at"] =
      String(getDate(postLoadData?.responseDeadline?.responseDate)) +
      " " +
      String(postLoadData?.responseDeadline?.responseTime);
      // String(getTime(postLoadData?.responseDeadline?.responseTime));

    postData["is_covid_certf_req_driver"] =
      postLoadData?.additionalScreen?.is_covid_certf_req_driver;
    postData["is_vehicle_tracking"] =
      postLoadData?.additionalScreen?.is_vehicle_tracking;
    postData["is_hse_compliant_vehicle"] =
      postLoadData?.additionalScreen?.is_hse_compliant_vehicle;

    postData["is_weightment_received_required"] =
      postLoadData?.additionalScreen?.is_weightment_received_required;

    postData["weightment_received_name"] =
      postLoadData?.additionalScreen?.weightment_received_name;

    postData["driver_requried_document_details"] =
      postLoadData?.additionalScreen?.driver_requried_document_details;

    postData["is_bonded_vehicle_required"] =
      postLoadData?.additionalScreen?.is_bonded_vehicle_required;

    postData["is_tarpaulin_plastic_cover"] =
      postLoadData?.additionalScreen?.is_tarpaulin_plastic_cover;

    postData["is_under_hook_delivery_required"] =
      postLoadData?.additionalScreen?.is_under_hook_delivery_required;

    postData["under_hook_delivery"] =
      postLoadData?.additionalScreen?.under_hook_delivery;

    // postData["rate_validity_date"] = moment(postLoadData?.additionalScreen?.rate_validity_date).format("YYYY-MM-DD");
    postData["is_advance_vehicle_driver_docs"] =
      postLoadData?.additionalScreen?.is_advance_vehicle_driver_docs;

    postData["is_driver_negative_pcr_required"] =
      postLoadData?.additionalScreen?.is_driver_negative_pcr_required;

    postData["detention_included_transport"] =
      postLoadData?.additionalScreen?.detention_included_transport;

    postData["is_cross_stuffing_required"] =
      postLoadData?.additionalScreen?.is_cross_stuffing_required;
    postData["is_allow_partial_load"] =
      postLoadData?.additionalScreen?.is_allow_partial_load;

    postData["is_cross_stuffing_box_vehicle_required"] =
      postLoadData?.additionalScreen?.is_cross_stuffing_box_vehicle_required;

    postData["cross_stuffing_no_of_packages"] =
      postLoadData?.additionalScreen?.cross_stuffing_no_of_packages;

    postData["documents"] = postLoadData?.documents || null;

    postData["recipients"] = removeDuplicate(recipients) || null;

    postData["ref_no"] = postLoadData?.ref_no || null;

    postData["cb"] = success;
    return postData;
  };

  const getLclData = (postData: any) => {
    postData = { ...postData, ...getCommonData(postData) };
    postData["gross_weight"] =
      postLoadData?.vehicleScreen?.values?.gross_weight;
    postData["total_freight_ton"] = numeral(
      postLoadData?.vehicleScreen?.values?.total_freight_ton
    ).format("0.00");

    postData["cargo_volume"] =
      postLoadData?.vehicleScreen?.values?.cargo_volume || null;
    postData["price_unit"] =
      postLoadData?.vehicleScreen?.values?.price_unit || null;
    postData["no_of_packages"] =
      postLoadData?.vehicleScreen?.values?.no_of_packages;
    postData["cargo_liter"] =
      postLoadData?.vehicleScreen?.values?.cargo_liter;
    postData["quote_by"] = postLoadData?.vehicleScreen?.values?.quote_by;
    postData["gross_weight"] =
      postLoadData?.vehicleScreen?.values?.gross_weight;
    if (
      postLoadData?.vehicleScreen?.values?.quote_by === QUOTE_BY.weightVolume
    ) {
      postData["price_per_freight"] =
        postLoadData?.vehicleScreen?.values?.price;

      postData["package_type_other"] =
        postLoadData?.vehicleScreen?.values?.package_type_other || "";

      postData["loading_price_per_freight"] =
        postLoadData?.vehicleScreen?.values?.loading_price;

      postData["unloading_price_per_freight"] =
        postLoadData?.vehicleScreen?.values?.unloading_price;

      postData["total_offer_price"] =
        postLoadData?.vehicleScreen?.values?.total_amount;

      // postData["vehicle_detention_rates"] =
      //   postLoadData?.vehicleScreen?.values?.vehicle_detention_rates;

      postData["detention_rate"] =
        postLoadData?.vehicleScreen?.values?.detention_rate;

      postData["detention_additional_charges"] =
        postLoadData?.vehicleScreen?.values?.detention_additional_charges;
      postData["detention_free_time"] =
        postLoadData?.vehicleScreen?.values?.detention_free_time;
      postData["detention_included_transport"] =
        postLoadData?.vehicleScreen?.values?.detention_included_transport;
      postData["max_bid"] =
        postLoadData?.vehicleScreen?.values?.max_bid;

      postData["is_genset"] = postLoadData?.vehicleScreen?.values?.is_genset;
      postData["required_temperature"] = null;

      // postData["required_temperature_min"] = postLoadData?.vehicleScreen?.values?.required_temperature[0];
      // postData["required_temperature_max"] = postLoadData?.vehicleScreen?.values?.required_temperature[1];

      // postData["is_boxed"] =
      //   postLoadData?.vehicleScreen?.values?.is_boxed || false;

      postData["package_type_id"] =
        postLoadData?.vehicleScreen?.packageType?.id;

      postData["preferred_vehicle_id"] =
        postLoadData?.vehicleScreen?.PreferredVehicleType?.id;


      postData["is_sidewall"] =
        postLoadData?.vehicleScreen?.values?.is_sidewall || false;

      // postData["is_any_suitable_vehicle"] =
      //   postLoadData?.vehicleScreen?.values?.is_any_suitable_vehicle || false;

      // postData["is_flat_bed_vehicle"] =
      //   postLoadData?.vehicleScreen?.values?.is_flat_bed_vehicle || false;

      // postData["is_low_bed_vehicle"] =
      //   postLoadData?.vehicleScreen?.values?.is_low_bed_vehicle || false;

      // postData["is_dum_truck_vehicle"] =
      //   postLoadData?.vehicleScreen?.values?.is_dum_truck_vehicle || false;

      postData["unloading_requirements"] =
        postLoadData?.vehicleScreen?.values?.unloading_requirements;
      postData["loading_requirements"] =
        postLoadData?.vehicleScreen?.values?.loading_requirements;
      postData["is_unloading_required"] =
        postLoadData?.vehicleScreen?.values?.is_unloading_required;
      postData["is_loading_required"] =
        postLoadData?.vehicleScreen?.values?.is_loading_required;
      postData["is_shared_vehicle_not_allowed"] =
        postLoadData?.vehicleScreen?.values?.is_shared_vehicle_not_allowed;
    }
    postData["categories"]["items"] = getVehiclesList(
      postLoadData?.vehicleScreen?.addedVehicles || []
    );
    postData["is_unit_basis"] = postLoadData?.vehicleScreen?.is_unit_basis
    if (postLoadData?.vehicleScreen?.is_unit_basis) {
      postData['added_units'] = postLoadData?.vehicleScreen?.added_units?.map(element => {
        return {
          ...element,
          total_offer_price: element?.total_amount || null,
          offer_price_per_vehicle: element?.price || null,
        }
      });
      postData['is_unit_basis'] = true
    }
    return postData;
  };

  const success = () => { };

  const getVehiclesList = (data: any) => {
    
    let item = [];
    data?.forEach((val: any) => {
      
      item.push({
        master_vehicle_type_id: val.selectVehicleTypeObj.id,
        max_vehicle_weight: val.max_vehicle_weight,
        vehicle_quantity: val.vehicle_quantity,
        container_type_id: val?.containerType?.id || null,

        is_boxed: val?.values?.is_boxed || false,
        is_sidewall: val?.values?.is_sidewall || false,
        is_any_suitable_vehicle: val?.values?.is_any_suitable_vehicle || false,
        is_flat_bed_vehicle: val?.values?.is_flat_bed_vehicle || false,
        is_low_bed_vehicle: val?.values?.is_low_bed_vehicle || false,
        is_dum_truck_vehicle: val?.values?.is_dum_truck_vehicle || false,

        preferred_vehicle_id: val?.PreferredVehicleType?.id || null,
        package_type_id: val?.packageType?.id || null,
        is_out_of_guage: val?.values?.is_out_of_guage || false,
        package_type_other: val?.values?.package_type_other || "",
        is_twin_load: val?.values?.is_twin_load || false,
        is_shipper_owned_container:
          val?.values?.is_shipper_owned_container || false,

        is_loading_required:
          val?.is_loading_required || val?.values?.is_loading_required || false,
        is_unloading_required:
          val?.is_unloading_required ||
          val?.values?.is_unloading_required ||
          false,
        loading_requirements:
          val?.loading_requirements ||
          val?.values?.loading_requirements ||
          val?.values?.loading_requirements ||
          null,
        unloading_requirements:
          val?.unloading_requirements ||
          val?.values?.unloading_requirements ||
          null,
        ...(postLoadData?.transaction_type_id === 1 && {
          total_offer_price: val?.total_amount || null,
          offer_price_per_vehicle: val?.price || null,
          loading_price: val?.loading_price || null,
          unloading_price: val?.unloading_price || null,
          detention_rate: val?.detention_rate || null,
          detention_additional_charges:
            val?.detention_additional_charges || null,
        }),
        detention_included_transport: val.hasOwnProperty(
          "detention_included_transport"
        )
          ? val?.detention_included_transport
          : val?.values?.detention_included_transport,

        detention_free_time: val?.hasOwnProperty("detention_free_time")
          ? val?.detention_free_time
          : val?.values?.detention_free_time,
        max_bid:
          val?.hasOwnProperty("max_bid") ? val?.max_bid : val?.values?.max_bid,

        is_genset:
          val?.hasOwnProperty("is_genset") ? val?.is_genset : val?.values?.is_genset,
        required_temperature: null,

        required_temperature_min:
          val?.hasOwnProperty("required_temperature_min") ? val?.required_temperature[0] : val?.values?.required_temperature[0],

        required_temperature_max:
          val?.hasOwnProperty("required_temperature_max") ? val?.required_temperature[1] : val?.values?.required_temperature[1],

      });
    });
    return item;
  };



  if (!contacts.filter(item => item == undefined).length) {
    return (
      <TitleLayout pageNumber={10} progress={100} title="Select Recipient">
        <div className="addNewItemBtn addNewBtnEdit">
          <OutLineButton
            classProp='tinyBtn'
            dataColor="blue"
            title="Add new"
            icon={
              <AddCircleOutlineOutlined />
            }
            onClick={() => {
              history.push("addRecipient");
            }}
          />
        </div>

        {/* search bar added */}

        <div className="networkSearchWrap">
          <FadeInComponent>
            <div className="searchContainer" style={{ width: "100%" }}>
              <input
                type="text"
                placeholder="Search"
                className="searchInput recipentSelectBox"
                value={search}
                style={{ width: "100%" }}
                onChange={(e) => setSearch(e.target.value)}
              />
              <span className={`icons-search ${search ? '' : 'active'}`}></span>
              <span
                className={`icons-cross ${search ? 'active' : ''}`}
                onClick={() => {
                  setSearch("")
                }}
              ></span>
            </div>
          </FadeInComponent>
        </div>



        {/* select checkbox for all recipients */}
        <div style={{
          marginBottom: 10
        }}>
          <FadeInComponent>
            <div className="flexRow">
              <div style={{
                width: '100%',
                height: 63
              }}>
                <SimpleCheckBox
                  style={{ height: 60 }}
                  checked={selectAllRecipients}
                  handleChange={(e) => {
                    if (!selectAllRecipients) {
                      handleCheckBoxForAllRecipients()
                      setSelectAllRecipients(true);
                    } else {
                      setRecipients([])
                      setSelectAllRecipients(false);
                    }
                  }}
                  labelName={"Select All Recipients"}
                  title={"Select All Recipients"}
                />
                <span className="selectedRecipientCounter">{recipients?.length}</span>
              </div>
              <div style={{
                width: "40%",
                height: 63,
                display: 'none'
              }}>
                <div className="selected-recipient-container">
                  <div style={{
                    marginBottom: 3
                  }} className="simpleLabel fw-5">Selected Recipient</div>
                  <div>{recipients?.length}</div>
                </div>

              </div>
            </div>
          </FadeInComponent>

        </div>
        {
          
        }

        <div className="recipentGridWrapper">
          <div className="recipentGridBoxWrap">
            {
              postLoadData?.from !== 'forward' && contacts?.length ?
                <Card index={0} val={contacts[0]} size={12} />
                : null
            }
            {
              contacts.length !== 0 && PostLoad?.from !== 'forward' &&
              contacts?.filter((item => item?.networkUser?.phone_no != mg_admin
                && item?.networkUser?.name.toLowerCase()
                  .includes(search.toLowerCase()) || item?.networkUser?.phone_no != mg_admin && item?.networkUser?.company?.length && item?.networkUser?.company[0]?.name?.toLowerCase()
                    .includes(search.toLowerCase()) || item?.networkUser?.company_name?.toLowerCase().includes(search.toLowerCase()) || item?.networkUser?.phone_no != mg_admin && item?.networkUser?.phone_no
                      .includes(search?.replace(/^0+/, ""))
              )).map((val: any, index) =>
                <Card val={val} index={index} />
              )
            }
            {
              PostLoad?.from == "forward" && contacts?.length && getDifference(contacts?.filter(item => item?.networkUser?.phone_no !== mg_admin), postLoadData?.forward_recipients).map((val, index) =>
                <Card index={index} val={val} />
              )
            }
          </div>
        </div>
        <FadeInComponent>
          <div className="bottomBtnContainer" style={{ marginBottom: "1rem" }}>
            <OutLineButton
              disabled={recipients?.filter((item) => item?.phone_no)?.length ? false : true}
              title="Save as Draft"
              onClick={() => {
                onSubmit(2);
              }}
            />
            <PrimaryButton
              disabled={recipients?.filter((item) => item?.phone_no)?.length ? false : true}
              title="Post"
              onClick={() => {
                onSubmit(1);
              }}
            />
          </div>
        </FadeInComponent>
      </TitleLayout>
    );
  } else {
    return (
      <div></div>
    )
  }
};
